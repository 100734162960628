import {
  Button,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import {
  AiOutlineEdit
} from 'react-icons/ai'
import { GuestMiddleware } from 'utilities/GuestMiddleware'

const UpdateButton = ({ onClick, tooltipText, noMargin=true,  tooltipPlacement }) => {
  return (
    <GuestMiddleware>
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={
        <Tooltip>
          {tooltipText ? tooltipText : 'Ubah data'}
        </Tooltip>
      }
    >
      <Button
        variant="success"
        size="sm"
        className={noMargin ? 'm-0' : 'm-1'}
        onClick={onClick}
      >
        <AiOutlineEdit style={{ fontSize: '.9rem' }} />
      </Button>
    </OverlayTrigger>
</GuestMiddleware>
  )
}

export default UpdateButton
