/* eslint-disable react-hooks/exhaustive-deps */
import { PenawaranApi } from "api"
import {
  ActionButton,
  Alert,
  ApprovalStatusButton,
  CreateButton,
  CRUDLayout,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  ReadButton,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  UpdateButton,
} from "components"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import { ButtonGroup, Card, Col, Modal, Nav, Row, Tab } from "react-bootstrap"
import { IoAlertCircleOutline } from "react-icons/io5"
import { useHistory, useLocation } from "react-router-dom"
import { DateConvert, PageNumber as TableNumber } from "utilities"
import { ModalFilter, TabFile, TabGambar } from "./Section"

const Penawaran = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const state = location?.state
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [data, setData] = useState([])
  console.log(data)
  const [dataFilter, setDataFilter] = useState({
    active: state?.dataFilter?.active,
    tgl_penawaran_mulai: state?.dataFilter?.tgl_penawaran_mulai,
    tgl_penawaran_selesai: state?.dataFilter?.tgl_penawaran_selesai,
    tgl_rab_mulai: state?.dataFilter?.tgl_rab_mulai,
    tgl_rab_selesai: state?.dataFilter?.tgl_rab_selesai,
    customer: state?.dataFilter?.customer,
    unit_produksi: state?.dataFilter?.unit_produksi,
    baseline: state?.dataFilter?.baseline,
    status_approval: state?.dataFilter?.status_approval,
    status_deal: state?.dataFilter?.status_deal,
  })
  const [showModalFilter, setShowModalFilter] = useState(false)
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [modalStatusDealConfig, setModalStatusDealConfig] = useState({
    show: false,
    data: {},
  })
  const [modalConfirmConfig, setModalConfirmConfig] = useState({
    show: false,
    data: {},
  })

  const [searchTime, setSearchTime] = useState(null)

  const getInitialData = () => {
    setIsPageLoading(true)

    PenawaranApi.getPagePenawaran({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
      tgl_penawaran_mulai: dataFilter?.tgl_penawaran_mulai,
      tgl_penawaran_selesai: dataFilter?.tgl_penawaran_selesai,
      tgl_rab_mulai: dataFilter?.tgl_rab_mulai,
      tgl_rab_selesai: dataFilter?.tgl_rab_selesai,
      customer: dataFilter?.customer,
      unit_produksi: dataFilter?.unit_produksi,
      baseline: dataFilter?.baseline,
      status_approval: dataFilter?.status_approval,
      status_deal: dataFilter?.status_deal,
    })
      .then(({ data }) => {
        setData(data.data)
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        })
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }

  const dismissAlert = () => {
    let state = { ...history.location.state }
    delete state.alert
    history.replace({ ...history.location, state })

    setAlertConfig({
      ...alertConfig,
      show: false,
    })
  }

  const onInputSearch = (text) => {
    const key = text.target.value
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: key,
        })
        setPaginationConfig({
          page: "1",
          dataLength: "10",
          totalPage: "1",
          dataCount: "0",
        })
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        })
      }, 750)
    )
  }

  useEffect(() => {
    setNavbarTitle("Penawaran")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
    dataFilter?.active,
    dataFilter?.tgl_penawaran_mulai,
    dataFilter?.tgl_penawaran_selesai,
    dataFilter?.tgl_rab_mulai,
    dataFilter?.tgl_rab_selesai,
    dataFilter?.customer,
    dataFilter?.unit_produksi,
    dataFilter?.baseline,
    dataFilter?.status_approval,
    dataFilter?.status_deal,
  ])

  const PageContent = () => {
    const checkStatus = (stat) => {
      const data = stat ? stat.toUpperCase() : ""
      const convert = data.substring(0, 3)

      if (convert === "APP")
        return {
          variant: "outline-success",
          label: "APPROVED",
        }
      // return "APPROVED";
      if (convert === "VER")
        return {
          variant: "outline-success",
          label: "VERIFIED",
        }
      // return "VERIFIED";
      if (convert === "REV")
        return {
          variant: "outline-warning",
          label: "REVISI",
        }
      // return "REVISI";
      if (convert === "REJ")
        return {
          variant: "outline-danger",
          label: "REJECT",
        }
      // return "REJECT";

      return {
        variant: "outline-secondary",
        label: "PENDING",
      }
      // return "PENDING";
    }

    const BarangJadiCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false)
      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return (
                  <li key={index} index={index}>
                    {brg.nama_item}
                  </li>
                )
              } else {
                return (
                  index < 2 && (
                    <li key={index} index={index}>
                      {brg.nama_item}
                    </li>
                  )
                )
              }
            })}
          </ul>
          {data.length > 2 && (
            <div
              className="text-primary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setisReadMoreClick((prev) => !prev)}
            >
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          )}
        </>
      )
    }

    const StatusDealBtn = ({ dataItem }) => {
      if (dataItem.status_deal === "tidak_deal") {
        return (
          <ActionButton
            disable
            className="btn-block text-nowrap"
            variant="danger"
            text="Tidak Deal"
            size="sm"
          />
        )
      }
      if (dataItem.status_deal === "deal") {
        return (
          <ActionButton
            disable
            className="btn-block text-nowrap"
            // style={{ padding: '10px 0px' }}
            variant="success"
            text="Deal"
            size="sm"
          />
        )
      }
      return (
        <ActionButton
          disable={dataItem.status_approval !== "APP"}
          className="btn-block text-nowrap"
          text="Belum Deal"
          size="sm"
          onClick={() => {
            if (dataItem.status_approval === "APP") {
              setModalStatusDealConfig({ show: true, data: dataItem })
            }
          }}
        />
      )
    }

    const DataTable = () => (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List Data Penawaran</b>
        </span>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Penawaran</ThFixed>
              <ThFixed>Informasi RAB</ThFixed>
              <Th>Customer</Th>
              <Th>Barang Jadi</Th>
              <Th>Unit Produksi</Th>
              <ThFixed>Baseline</ThFixed>
              <ThFixed>Status Approval</ThFixed>
              <ThFixed>Status Deal</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-start">
                    <ButtonGroup size="sm">
                      <ReadButton
                        onClick={() =>
                          history.push(
                            `/transaksi/penawaran/detail/${val.id_penawaran}`,
                            {
                              dataFilter,
                            }
                          )
                        }
                      />
                      {val.status_approval === "REV" || val.status_approval === "APP" ? (<UpdateButton
                        onClick={() =>
                          history.push(
                            `/transaksi/penawaran/ubah/${val.id_penawaran}`,
                            {
                              dataFilter,
                            }
                          )
                        }
                      />
                      ) : null}
                    </ButtonGroup>
                  </div>
                </TdFixed>
                <TdFixed>
                  <div>{DateConvert(new Date(val.tgl_penawaran)).custom}</div>
                  <div>{val.no_penawaran ?? "-"}</div>
                </TdFixed>
                <TdFixed>
                  <div>{DateConvert(new Date(val.tgl_rab)).custom}</div>
                  <div>{val.no_rab ?? "-"}</div>
                </TdFixed>
                <Td>{val.nama_customer ?? "-"}</Td>
                <Td>
                  {val.barang_jadi && val.barang_jadi?.length > 0 ? (
                    <BarangJadiCollapse data={val.barang_jadi} />
                  ) : (
                    "-"
                  )}
                </Td>
                <Td>{val.nama_unit_produksi ?? "-"}</Td>
                <Td textCenter>{val.baseline}</Td>
                <TdFixed textCenter>
                  <ApprovalStatusButton
                    variant={checkStatus(val.status_approval).variant}
                  >
                    {checkStatus(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
                <TdFixed textCenter>
                  <StatusDealBtn dataItem={val} />
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    )

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    )
  }

  const InfoItem = ({ title, value }) => (
    <tr>
      <td>{title}</td>
      <td className="pl-4 pr-2">:</td>
      <td>{value}</td>
    </tr>
  )

  const ModalStatusDeal = () => {
    console.log(modalStatusDealConfig)

    const formInitialValues = { path_gambar: [] }
    console.log(formInitialValues)
    return (
      <Modal
        closeButton
        show={modalStatusDealConfig.show}
        title="Status Deal"
        onHide={() => setModalStatusDealConfig({ show: false })}
        size="lg"
      >
        <Modal.Header>
          <b>Ubah Status Deal?</b>
        </Modal.Header>
        <Modal.Body>
          <Formik initialValues={formInitialValues}>
            {(formik) => (
              <>
                <Row>
                  <Col>
                    <table style={{ fontSize: "14px" }}>
                      <tbody>
                        <InfoItem
                          title="Tgl. Penawaran"
                          value={
                            modalStatusDealConfig?.data?.tgl_penawaran
                              ? DateConvert(
                                new Date(
                                  modalStatusDealConfig?.data?.tgl_penawaran
                                )
                              ).custom
                              : "-"
                          }
                        />
                        <InfoItem
                          title="No. Penawaran"
                          value={
                            modalStatusDealConfig?.data?.no_penawaran ?? "-"
                          }
                        />
                        <InfoItem
                          title="Customer"
                          value={
                            modalStatusDealConfig?.data?.nama_customer ?? "-"
                          }
                        />
                        <InfoItem
                          title="ATT"
                          value={modalStatusDealConfig?.data?.att ?? "-"}
                        />
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <Card>
                  <Tab.Container defaultActiveKey="tab-1">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                      <Nav variant="tabs">
                        <Nav.Item>
                          <Nav.Link eventKey="tab-1">Gambar</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="tab-2">File</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Card.Header>
                    <Tab.Content>
                      <Tab.Pane eventKey="tab-1">
                        <div className="m-2">
                          <TabGambar dataGambar={formik} />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="tab-2">
                        <div className="m-2">
                          <TabFile dataFile={formik} />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Card>
                <div className="d-flex justify-content-center">
                  <ActionButton
                    variant="danger"
                    text="Tidak Deal"
                    className="m-1 px-4"
                    onClick={() => {
                      setModalConfirmConfig({
                        show: true,
                        data: {
                          status_deal: "tidak_deal",
                          path_gambar: formik?.values?.path_gambar,
                        },
                      })
                      setModalStatusDealConfig({
                        ...modalStatusDealConfig,
                        show: false,
                      })
                    }}
                  />
                  <ActionButton
                    variant="primary"
                    text="Deal"
                    className="m-1 px-4"
                    onClick={() => {
                      setModalConfirmConfig({
                        show: true,
                        data: {
                          status_deal: "deal",
                          path_gambar: formik?.values?.path_gambar,
                        },
                      })
                      setModalStatusDealConfig({
                        ...modalStatusDealConfig,
                        show: false,
                      })
                    }}
                  />
                </div>
              </>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    )
  }

  const ModalConfirm = () => {
    const [isSubmitting, setIsSubmitting] = useState(false)

    const showFile = {
      values: { path_gambar: modalConfirmConfig.data.path_gambar ?? [] },
    }
    const onSubmitHandler = () => {
      setIsSubmitting(true)

      const finalValues = {
        id_penawaran: modalStatusDealConfig.data.id_penawaran,
        status_deal: modalConfirmConfig.data.status_deal,
        file: modalConfirmConfig.data.path_gambar,
      }
      console.log("simpan", finalValues)

      // KIRIM DATA KE SERVER
      PenawaranApi.statusDeal(finalValues)
        .then(() => {
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Data berhasil disimpan!",
          })
          setModalConfirmConfig({
            show: false,
            data: {},
          })
          getInitialData()
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal disimpan!",
          })
          setModalConfirmConfig({
            show: false,
            data: {},
          })
        })
        .finally(() => setIsSubmitting(false))
    }

    return (
      <Modal
        closeButton
        show={modalConfirmConfig.show}
        onHide={() => {
          setModalConfirmConfig({
            show: false,
            data: {},
          })
        }}
        title="Konfirmasi Status Deal"
      >
        <Modal.Body>
          <div className="text-center">
            <IoAlertCircleOutline size={80} className="text-danger mb-3" />
          </div>
          <div className="text-center">
            <b className="text-center">
              Apakah anda yakin akan menyimpan Status Deal data ini?
            </b>
          </div>
          <div className="container mx-3">
            <Row>
              <Col>
                <table style={{ fontSize: "14px" }}>
                  <tbody>
                    <InfoItem
                      title="Tgl. Penawaran"
                      value={
                        modalStatusDealConfig?.data?.tgl_penawaran
                          ? DateConvert(
                            new Date(modalStatusDealConfig.data.tgl_penawaran)
                          ).custom
                          : "-"
                      }
                    />
                    <InfoItem
                      title="No. Penawaran"
                      value={modalStatusDealConfig?.data?.no_penawaran ?? "-"}
                    />
                    <InfoItem
                      title="Customer"
                      value={modalStatusDealConfig?.data?.nama_customer ?? "-"}
                    />
                    <InfoItem
                      title="ATT"
                      value={modalStatusDealConfig?.data?.att ?? "-"}
                    />
                    <InfoItem
                      title="Status Deal"
                      value={
                        modalConfirmConfig.data.status_deal === "deal"
                          ? "Deal"
                          : "Tidak Deal"
                      }
                    />
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
          <Card>
            <Tab.Container defaultActiveKey="tab-1">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="tab-1">Gambar</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab-2">File</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Tab.Content>
                <Tab.Pane eventKey="tab-1">
                  <div className="m-2">
                    <TabGambar dataGambar={showFile} readOnly />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="tab-2">
                  <div className="m-2">
                    <TabFile dataFile={showFile} readOnly />
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card>
          <div className="text-center">
            <small className="text-danger">
              Data yang telah disimpan tidak dapat diedit!
            </small>
          </div>
          <br />
          <div className="d-flex justify-content-center">
            <ActionButton
              variant="outline-danger"
              text="Batal"
              className="m-1 px-4"
              onClick={() => {
                setModalConfirmConfig({
                  show: false,
                  data: {},
                })
              }}
            />
            <ActionButton
              variant="primary"
              text="Simpan"
              className="m-1 px-4"
              loading={isSubmitting}
              onClick={onSubmitHandler}
            />
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearch} />
            <FilterButton active={dataFilter.active} onClick={() => setShowModalFilter(true)} />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push("/transaksi/penawaran/list-rab", { dataFilter })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => dismissAlert()}
      />

      {/* Modal */}
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <>
          <PageContent />
          {modalStatusDealConfig.show && <ModalStatusDeal />}
          {modalConfirmConfig.show && <ModalConfirm />}
        </>
      )}
    </CRUDLayout>
  )
}

export default Penawaran
