import { Formik } from "formik"
import { useEffect, useState } from "react"
import { ButtonGroup, Modal } from "react-bootstrap"
import * as Yup from "yup"
import { PeringkatPeluangApi } from "../../../api"
import {
  ActionButton,
  Alert,
  CreateButton,
  CreateModal,
  CRUDLayout,
  DataStatus,
  DeleteButton,
  DeleteModal,
  Input,
  InputSearch,
  Pagination,
  Switch,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  UpdateButton,
  UpdateModal
} from "../../../components"
import { PageNumber } from "../../../utilities"

const PeringkatPeluang = ({ setNavbarTitle }) => {
  const title = "Peringkat Peluang"

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(false)

  // STATE SEARCHING
  const [isSearching, setIsSearching] = useState(false)
  const [searchKey, setSearchKey] = useState("")

  // MODALS SHOW STATE
  const [isCreateForm, setIsCreateForm] = useState(false) // MODAL TAMBAH STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false) // MODAL UPDATE STATE
  const [isDeleteData, setIsDeleteData] = useState(false) // MODAL HAPUS STATE

  // STATE DATA SATUAN
  const [data, setData] = useState([])
  const [dataCount, setDataCount] = useState(0)
  const [page, setPage] = useState(1)
  const [dataLength, setDataLength] = useState(10)
  const [totalPage, setTotalPage] = useState(1)
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([])
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState([])
  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false)
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  })

  const [searchTime, setSearchTime] = useState(null)

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true)
    setIsSearching(false)
    setShowAlert(false)

    PeringkatPeluangApi.getPage(page, dataLength, searchKey)
      .then((res) => {
        setData(res.data.data)
        setTotalPage(res.data.total_page)
        setDataCount(res.data.data_count)
      })
      .catch((err) => alert(err))
      .finally(() => {
        if (searchKey != "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          })
          setShowAlert(true)
        }
        setIsLoading(false)
      })
  }

  //function input searchConfig
  const onInputSearch = (text) => {
    const key = text.target.value
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchKey(key)
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
        })
        setShowAlert(true)
      }, 750)
    )
  }

  // FUNCTION SWITCH HIDDEN DATA
  const changeDataStatus = (status, id) => {
    setIsLoading(true)
    setShowAlert(false)

    const value = {
      id_peringkat_peluang: id,
    }

    const onLoadedSuccess = () => {
      setIsSearching(false)
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      })
      setShowAlert(true)
    }

    const onLoadedFailed = () => {
      setIsSearching(false)
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      })
      setShowAlert(true)
    }

    status === true
      ? PeringkatPeluangApi.show(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
      : PeringkatPeluangApi.hide(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
  }

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title) // SET JUDUL NAVBAR
    getData() // GET DATA DARI SERVER

    return () => {
      setIsLoading(false)
      setIsSearching(false)
    }
  }, [setNavbarTitle, page, dataLength, searchKey])

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    nama_peringkat_peluang: Yup.string().required("Nama Peringkat Peluang wajib diisi"),
  })

  // MODAL TAMBAH COMPONENT
  const TambahModal = () => {
    // FORMIK INITIAL VALUES
    const formInitialValues = {
      nama_peringkat_peluang: "",
      keterangan: "",
    }

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      PeringkatPeluangApi.create(values)
        .then(() => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Tambah data berhasil!",
          })
        })
        .catch((err) => {
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
          })
        })
        .finally(() => {
          // CLOSE MODAL
          setIsCreateForm(false)
          // TAMPILKAN ALERT
          setShowAlert(true)
          // FETCH DATA DARI SERVER
          getData()
        })
    }

    return (
      <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Input
                  label="Nama Peringkat Peluang"
                  type="text"
                  name="nama_peringkat_peluang"
                  value={values.nama_peringkat_peluang}
                  onChange={handleChange}
                  error={errors.nama_peringkat_peluang && touched.nama_peringkat_peluang && true}
                  errorText={errors.nama_peringkat_peluang}
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                />
              </Modal.Body>

              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Tambah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </CreateModal>
    )
  }

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const formInitialValues = {
      id_peringkat_peluang: updateData.id_peringkat_peluang,
      nama_peringkat_peluang: updateData.nama_peringkat_peluang,
      keterangan: updateData.keterangan,
    }

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      // SEND UPDATED DATA TO SERVER
      PeringkatPeluangApi.update(values)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          })
        })
        .catch((err) => {
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal! (${err.response.data.message})`,
          })
        })
        .finally(() => {
          // CLOSE UPDATE MODAL FORM
          setIsUpdateForm(false)
          // SHOW ALERT
          setShowAlert(true)
          // FETCH DATA DARI SERVER
          getData()
        })
    }

    return (
      <UpdateModal show={isUpdateForm} onHide={() => setIsUpdateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Input
                  label="Nama Peringkat Peluang"
                  type="text"
                  name="nama_peringkat_peluang"
                  value={values.nama_peringkat_peluang}
                  onChange={handleChange}
                  error={errors.nama_peringkat_peluang && touched.nama_peringkat_peluang && true}
                  errorText={errors.nama_peringkat_peluang}
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                />
              </Modal.Body>

              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Ubah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    )
  }

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_peringkat_peluang: deleteData.id_peringkat_peluang }

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false)

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true)

      PeringkatPeluangApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          })
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err.response.data.message})`,
          })
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false)
          // TAMPIL ALERT
          setShowAlert(true)
          // FETCH DATA DARI SERVER
          getData()
        })
    }

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
      >
        <div>Nama Peringkat Peluang : {deleteData.nama_peringkat_peluang}</div>
      </DeleteModal>
    )
  }

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <Th>Peringkat Peluang</Th>
              <Th>Keterangan</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_peringkat_peluang}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <ButtonGroup size="sm">
                        <UpdateButton
                          onClick={() => {
                            setUpdateData(val)
                            setIsUpdateForm(true)
                          }}
                        />
                        <DeleteButton
                          onClick={() => {
                            setDeleteData(val)
                            setIsDeleteData(true)
                          }}
                        />
                      </ButtonGroup>

                      <Switch
                        id={val.id_peringkat_peluang}
                        checked={val.is_hidden ? false : true}
                        onChange={() => changeDataStatus(val.is_hidden, val.id_peringkat_peluang)}
                      />
                    </div>
                  </TdFixed>
                  <Td>{val.nama_peringkat_peluang}</Td>
                  <Td>{val.keterangan}</Td>
                </Tr>
              )
            })}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataNumber={page * dataLength - dataLength + 1}
            dataPage={page * dataLength}
            dataCount={dataCount}
            onDataLengthChange={(e) => {
              setPage(1)
              setDataLength(e.target.value)
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearch} />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => setIsCreateForm(true)} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <TambahModal />
      <UbahModal />
      <HapusModal />
    </CRUDLayout>
  )
}

export default PeringkatPeluang
