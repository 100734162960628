/* eslint-disable react-hooks/exhaustive-deps */
import { LaporanPeluangApi } from "api"
import Axios from "axios"
import {
  ActionButton,
  Alert,
  CRUDLayout,
  DataStatus,
  DatePicker,
  Pagination,
  SelectSearch,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
} from "components"
import FileSaver from "file-saver"
import { useEffect, useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { IoDocumentTextOutline } from "react-icons/io5"
import { DateConvert, DecimalConvert, TableNumber } from "utilities"

const LaporanPeluang = ({ setNavbarTitle }) => {
  const TODAY = new Date().toISOString().slice(0, 10)
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [dropdown, setDropdown] = useState({
    peringkat_peluang: [],
    customer: [],
  })
  const [form, setForm] = useState({
    tanggal_pl_start: TODAY,
    tanggal_pl_end: TODAY,
    nama_peringkat_peluang: null,
    nama_customer: null,
  })
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  })
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    show: false,
  })

  const mapDataFromServer = (data) => {
    const uniqNumber = []
    // const mappedData = data.map(item => {
    //   if (!uniqNumber.includes(item.no_peluang)) {
    //     uniqNumber.push(item.no_peluang)
    //     return item
    //   }
    //   return Object({ nama_item: item.nama_item, qty: item.qty })
    // })
    return data
  }

  const getInitialData = () => {
    Axios.all([
      LaporanPeluangApi.getPeringkatPeluang(),
      LaporanPeluangApi.getCustomer(),
    ])
      .then(
        Axios.spread((resPeringkatPeluang, resCustomer) => {
          const peringkat_peluang = resPeringkatPeluang?.data?.data?.map(
            (item) =>
              Object({
                label: item.nama_peringkat_peluang,
                value: item.nama_peringkat_peluang,
              })
          )

          const customer = resCustomer?.data?.data?.map((item) =>
            Object({
              label: item.nama_customer,
              value: item.nama_customer,
            })
          )

          setDropdown({ peringkat_peluang, customer })
        })
      )
      .catch(() =>
        setAlertConfig({
          variant: "danger",
          text: "Data dropdown gagal dimuat!",
          show: true,
        })
      )
  }

  const handleFetchReport = () => {
    setLoading(true)

    const query = {
      ...form,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    }

    LaporanPeluangApi.getPage(query)
      .then(({ data }) => {
        setData(mapDataFromServer(data.data ?? []))
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        })
      })
      .catch(() =>
        setAlertConfig({
          variant: "danger",
          text: "Tidak dapat memuat data!",
          show: true,
        })
      )
      .finally(() => setLoading(false))
  }

  const handleButtonExport = (tipe) => {
    const query = { ...form, tipe }

    LaporanPeluangApi.getPeluangExport(query).then(({ data }) => {
      const ext = tipe === "excel" ? "xlsx" : "pdf"
      FileSaver.saveAs(data.data, `Laporan_Peluang.${ext}`)
    })
  }

  useEffect(() => getInitialData(), [])

  useEffect(() => {
    setNavbarTitle("Laporan Peluang")
    handleFetchReport()
  }, [setNavbarTitle, paginationConfig.page, paginationConfig.dataLength])

  // Reset page on form filter change
  useEffect(
    () =>
      setPaginationConfig({
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }),
    [form]
  )

  const ExportButton = ({
    variant = "outline-success",
    text = "Export EXCEL",
    onClick,
  }) => {
    return (
      <Button variant={variant} onClick={onClick} className="mx-1 mb-1">
        <IoDocumentTextOutline size={18} />
        <span className="ml-2 text-uppercase">{text}</span>
      </Button>
    )
  }

  const TableSection = () => {
    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Tgl. Peluang</ThFixed>
              <ThFixed>No. Peluang</ThFixed>
              <Th>Customer</Th>
              <ThFixed>Peringkat Peluang</ThFixed>
              <Th>Description (Barang Jadi)</Th>
              <Th>Qty.</Th>
              <Th>Proyek</Th>
              <ThFixed>Status Instalasi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((item, index) => {
              return (
                <Tr key={index}>
                  <TdFixed>
                    {TableNumber(
                      paginationConfig.page,
                      paginationConfig.dataLength,
                      index
                    )}
                  </TdFixed>
                  <Td className="text-center">
                    {item.no_peluang
                      ? item.tgl_peluang
                        ? DateConvert(new Date(item.tgl_peluang)).custom
                        : "-"
                      : ""}
                  </Td>
                  <Td>{item.no_peluang ? item.no_peluang : ""}</Td>
                  <Td>{item.no_peluang ? item.nama_customer : ""}</Td>
                  <Td>{item.no_peluang ? item.nama_peringkat_peluang : ""}</Td>
                  <Td>{item?.nama_item ?? "-"}</Td>
                  <Td className="text-right">
                    {DecimalConvert(parseFloat(item?.qty ?? 0)).getWithComa}
                  </Td>
                  <Td>{item.no_peluang ? item.nama_proyek : ""}</Td>
                  <Td className="text-center">
                    {item.no_peluang ? (item.instalasi ? "Ya" : "Tidak") : ""}
                  </Td>
                </Tr>
              )
            })}
          </TBody>
        </CRUDLayout.Table>

        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </>
    )
  }

  return (
    <CRUDLayout>
      <Row className="bg-white rounded border shadow-sm gap-1 mx-1">
        {/* Periode */}
        <Col md={6}>
          <DatePicker
            selectsRange
            label="Tgl. Peluang"
            placeholderText="Pilih tanggal peluang"
            startDate={
              form.tanggal_pl_start ? new Date(form.tanggal_pl_start) : ""
            }
            endDate={form.tanggal_pl_end ? new Date(form.tanggal_pl_end) : ""}
            onChange={(dates) => {
              const [start, end] = dates
              const startDate = start ? DateConvert(start).default : null
              const endDate = end ? DateConvert(end).default : null

              setForm({
                ...form,
                tanggal_pl_start: startDate,
                tanggal_pl_end: endDate,
              })
            }}
            monthsShown={2}
          />
        </Col>

        {/* Filter */}
        <Col
          md={6}
          className="d-flex flex-row justify-content-start align-items-center gap-2"
        >
          <span className="mt-1 text-nowrap">Filter By:</span>
          <SelectSearch
            wrapperClassName="w-25 mx-2"
            label="Peringkat Peluang"
            placeholder="Pilih peringkat"
            option={dropdown?.peringkat_peluang ?? []}
            onChange={(val) => {
              setForm((prev) => ({
                ...prev,
                nama_peringkat_peluang: val.value,
              }))
            }}
          />

          <div style={{ minWidth: 200 }}>
            <SelectSearch
              label="Customer"
              placeholder="Pilih customer"
              option={dropdown?.customer ?? []}
              onChange={(val) => {
                setForm((prev) => ({ ...prev, nama_customer: val.value }))
              }}
            />
          </div>
          <ActionButton

            className="ml-2 mt-3 p-2 text-nowrap"
            size="sm"
            text="LIHAT DATA"
            onClick={handleFetchReport}
          />
        </Col>
      </Row>


      <Alert
        showCloseButton={true}
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig((prev) => ({ ...prev, show: false }))}
      />

      {!isLoading && data.length > 0 && (
        <Row className="d-flex justify-content-end align-items-center mx-1 mt-4 mb-1">
          <ExportButton
            variant="outline-danger"
            text="Export PDF"
            onClick={() => handleButtonExport("pdf")}
          />
          <ExportButton onClick={() => handleButtonExport("excel")} />
        </Row>
      )}

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat data ..." />
      ) : (
        <TableSection />
      )}
    </CRUDLayout>
  )
}

export default LaporanPeluang
