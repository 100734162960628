/* eslint-disable react-hooks/exhaustive-deps */
import { RegistrasiPeluangApi } from "api"
import {
  ActionButton,
  Alert,
  ApprovalStatusButton,
  CreateButton,
  CRUDLayout,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  ReadButton,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  UpdateButton,
} from "components"
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { DateConvert, PageNumber as TableNumber } from "utilities"
import { ModalFilter } from "./Section"
import { ButtonGroup } from "react-bootstrap"

const RegistrasiPeluang = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const state = location?.state
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [data, setData] = useState([])
  const [dataFilter, setDataFilter] = useState({
    active: state?.dataFilter?.active,
    tgl_peluang_mulai: state?.dataFilter?.tgl_peluang_mulai,
    tgl_peluang_selesai: state?.dataFilter?.tgl_peluang_selesai,
    peringkat_peluang: state?.dataFilter?.peringkat_peluang,
    customer: state?.dataFilter?.customer,
    proyek: state?.dataFilter?.proyek,
    unit_produksi: state?.dataFilter?.unit_produksi,
    status_approval: state?.dataFilter?.status_approval,
  })
  const [showModalFilter, setShowModalFilter] = useState(false)
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })

  const [searchTime, setSearchTime] = useState(null)

  const getInitialData = () => {
    setIsPageLoading(true)

    RegistrasiPeluangApi.getPage({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
      tgl_peluang_mulai: dataFilter?.tgl_peluang_mulai,
      tgl_peluang_selesai: dataFilter?.tgl_peluang_selesai,
      peringkat_peluang: dataFilter?.peringkat_peluang,
      customer: dataFilter?.customer,
      proyek: dataFilter?.proyek,
      unit_produksi: dataFilter?.unit_produksi,
      status_approval: dataFilter?.status_approval,
    })
      .then(({ data }) => {
        setData(data.data)
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        })
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }

  const onInputSearch = (text) => {
    const key = text.target.value
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: key,
        })
        setPaginationConfig({
          page: "1",
          dataLength: "10",
          totalPage: "1",
          dataCount: "0",
        })
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        })
      }, 750)
    )
  }

  useEffect(() => {
    setNavbarTitle("Peluang")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
    dataFilter?.active,
    dataFilter?.tgl_peluang_mulai,
    dataFilter?.tgl_peluang_selesai,
    dataFilter?.peringkat_peluang,
    dataFilter?.customer,
    dataFilter?.proyek,
    dataFilter?.unit_produksi,
    dataFilter?.status_approval,
  ])

  const PageContent = () => {
    const getStatusApprovalLabel = (status_approval) => {
      switch (status_approval) {
        case "APP":
          return {
            variant: "outline-success",
            label: "APPROVED",
          }
        case "REJ":
          return {
            variant: "outline-danger",
            label: "REJECT",
          }
        case "REV":
          return {
            variant: "outline-warning",
            label: "REVISI",
          }
        case "VER":
          return {
            variant: "outline-success",
            label: "VERIFIED",
          }
        default:
          return {
            variant: "outline-secondary",
            label: "PENDING",
          }
      }
    }

    const BarangJadiCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false)
      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return (
                  <li key={index} index={index}>
                    {brg.nama_item}
                  </li>
                )
              } else {
                return (
                  index < 2 && (
                    <li key={index} index={index}>
                      {brg.nama_item}
                    </li>
                  )
                )
              }
            })}
          </ul>
          {data.length > 2 && (
            <div
              className="text-primary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setisReadMoreClick((prev) => !prev)}>
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          )}
        </>
      )
    }

    const DataTable = () => (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List Data Peluang</b>
        </span>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Peluang</ThFixed>
              <ThFixed>Informasi Info Peluang</ThFixed>
              <ThFixed>Peringkat Peluang</ThFixed>
              <Th>Customer</Th>
              <Th>Barang Jadi</Th>
              <Th>Unit Produksi</Th>
              <ThFixed>Status Approval</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-start">
                    <ButtonGroup size="sm">
                      <ReadButton
                        onClick={() =>
                          history.push(
                            `/transaksi/registrasi-peluang/detail/${val.id_peluang}`,
                            {
                              dataFilter,
                            }
                          )
                        }
                      />
                      {val.status_approval === "REV" && (
                        <UpdateButton
                          onClick={() =>
                            history.push(
                              `/transaksi/registrasi-peluang/ubah/${val.id_peluang}`,
                              {
                                dataFilter,
                              }
                            )
                          }
                        />
                      )}
                    </ButtonGroup>
                  </div>
                </TdFixed>
                <TdFixed>
                  <div>{DateConvert(new Date(val.tgl_peluang)).custom}</div>
                  <div>{val.no_peluang ?? "-"}</div>
                </TdFixed>
                <TdFixed>
                  <div>
                    {DateConvert(new Date(val.tgl_info_peluang)).custom}
                  </div>
                  <div>{val.no_info_peluang ?? "-"}</div>
                </TdFixed>
                <TdFixed className="text-nowrap">
                  {val.nama_peringkat_peluang}
                </TdFixed>
                <Td>{val.nama_customer ?? "-"}</Td>
                <Td style={{ minWidth: "260px" }}>
                  {val.barang_jadi && val.barang_jadi?.length > 0 ? (
                    <BarangJadiCollapse data={val.barang_jadi} />
                  ) : (
                    "-"
                  )}
                </Td>
                <Td>{val.nama_unit_produksi ?? "-"}</Td>
                <TdFixed>
                  <ApprovalStatusButton
                    variant={
                      getStatusApprovalLabel(val.status_approval).variant
                    }>
                    {getStatusApprovalLabel(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    )

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          {/* <Row className="mb-2">
            <Col md={8}> */}
          <div className="d-flex mb-3">
            <InputSearch
              onChange={onInputSearch}
            />
            <FilterButton
              active={dataFilter.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
          {/* </Col>
          </Row> */}
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push("/transaksi/registrasi-peluang/list-info-peluang", {
                dataFilter,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Modal */}
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
    </CRUDLayout>
  )
}

export default RegistrasiPeluang
